.extensions {
    padding: 0.5rem 15%;
}

.extensions-text-container {
    padding: 1rem 15%;
}

.extensions-bullet {
    margin-bottom: .7rem;
}

.heading-primary-light {
    text-align: center;
}

.error-message {
    text-align: center;
    padding-top: 3rem;
    padding-bottom: 3rem;
}

.spinner-container {
    padding-top: 5rem;
    padding-bottom: 50rem;
}