.default-503 {
    padding: 4rem 25%;
    text-align: center;
}

.default-503-icon {
    width: min(80%, 20rem);
    margin-bottom: 2.5rem;
}

@media (max-width: 960px) {
    .default-503 {
        padding: 4rem 15%;
    }
}