.showcase {
    text-align: center;
    padding: 2rem 13%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.showcasewiki-box {
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    background-color: #ffffff;
    /* display: inline-block; */
    text-align: center;
    border: 1px solid #e2e2e2;
    border-radius: 15px;
    padding: 2rem 1.5rem;
    margin: 1rem;
    width: min(80%, 15rem);
    /* height: min(80%, 15rem); */
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.showcasewiki-box:hover {
    background-color: #ECF8FF;
}

.showcasewiki-logo {
    margin-top: 1rem;
    height: 100px;
}