.nav-area-grid {
    display: grid;
    grid-template-areas:
        "banneralert"
        "navbar";
}

.change-settings {
    /* display: none; */
    padding: 1rem 15% 3rem 15%;
}

.navbar-container {
    grid-area: navbar;
	position: fixed;
	top: 0;
	z-index: 99;
    background: #ffffff;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	color: #363636;
	width: 100vw;
	height: 80px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: .7rem 0;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.navbar-container.banner-open {
    margin-top: 12rem;
}

.navbar-container.active {
    background: #ffffff;
    -webkit-box-shadow: 0 2px 5px #777;
            box-shadow: 0 2px 5px #777;
}

.navbar-logo-container {
	text-decoration: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
    margin-left: 14.6%;
}

.navbar-logo {
	width: 40px;
    max-height:40px;
}

.logo-text {
    font-size: 1.5rem;
    margin-left: .5rem;
}

.menu-icon {
	display: none;
}

.menu-icon:hover {
    cursor: pointer;
}

.navbar-list {
    list-style: none;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
    padding: 0;
    margin-right: 16%;
}

.navbar-item {
    /* padding: 1rem; */
    /* margin: 1rem; */
}

.navbar-language-dropdown {
    /* padding: 1rem; */
    /* margin: 1rem; */
    width: 10rem;
    margin-top: 2rem;
}

.navbar-link {
	color: #363636;
	text-decoration: none;
	padding: 1rem;
}

.navbar-link:hover {
    cursor: pointer;
    opacity: .7;
}

.navbar-btn {
    padding: .8rem 2rem;
}

.navbar-btn-item:not(:last-child) {
    margin-right: 1rem;
}

.navbar-item.more {
	position: relative;
    display: inline-block;
    margin-right: 1.3rem;
}

.caret-down {
    display: inline;
    margin-bottom: 1px;
}

.dropdown-menu {
	text-align: left;
	background-color: #ffffff;
	font-size: 0.8rem;
	list-style: none;
	position: absolute;
	top: 3rem;
	left: 50%;
	-webkit-transform: translateX(-50%);
	    -ms-transform: translateX(-50%);
	        transform: translateX(-50%);
	padding:0;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	-webkit-box-shadow: 0 0 10px #8D8D8D;
	        box-shadow: 0 0 10px #8D8D8D;
	width: 300%;
	/* display: none; */
}

.dropdown-item {
    /* padding: .3rem 1rem; */
	border-bottom: 1px solid #c7c7c7;
}

.dropdown-item:hover {
    cursor: pointer;
    background-color: #f5f5f5;
}

.dropdown-item:last-child {
    border-bottom: none;
}

.dropdown-link {
    padding: .5rem 1rem;
    display: inline-block;
    width: 100%;
    color: #363636;
	text-decoration: none;
}


@media (max-width: 1150px) {
    .navbar-container {
        background: #ffffff !important;
        padding: 1rem 0;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        max-height: 100vh;
    }

    .menu-icon.bars {
        text-align: center;
        display: initial;
        position: absolute;
        top: 1.6rem;
        right: 16%;
    }

    .menu-icon.close {
        text-align: center;
        display: initial;
        position: absolute;
        margin: 0;
        padding: 0;
        top: 1.3rem;
        right: 16.6%;
    }

    .logo-text {
        /* display: none; */
    }

    .navbar-list {
        overflow-y: scroll;
        width: 100vw;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-animation-name: navbarOpen;
                animation-name: navbarOpen;
        -webkit-animation-duration: .3s;
                animation-duration: .3s;
        -webkit-animation-timing-function: ease-out;
                animation-timing-function: ease-out;
        padding: 0;
        margin-right: 0;
    }

    @-webkit-keyframes navbarOpen {
        from {
            -webkit-transform: translateX(100vw);
                    transform: translateX(100vw);
            opacity: 0;
            background-color: transparent;
        } to {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
        }
    }

    @keyframes navbarOpen {
        from {
            -webkit-transform: translateX(100vw);
                    transform: translateX(100vw);
            opacity: 0;
            background-color: transparent;
        } to {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            opacity: 1;
        }
    }

    .navbar-list.closed {
        display: none;
    }

    .navbar-item:first-child {
        border-top: .1px solid #c7c7c7;
    }

    .navbar-item {
        /* display: block; */
        width: calc(100vw);
        margin: 0;
        border-bottom: 1px solid #c7c7c7;
        padding: 0;
    }

    .navbar-btn-item {
        width: 100vw;
    }

    .navbar-link {
        margin: 0;
        display: block;
        padding: 1rem 13% 1rem 14.6%;
        width: calc(100vw - 27.6%);
    }

    .navbar-item.more {
        margin-bottom: 2rem;
    }

    .dropdown-menu {
        -webkit-box-shadow: none;
                box-shadow: none;
        width: calc(80vw);
        position: static;
        -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
                transform: translateX(0);
        padding-left: 14.6%;
        padding-right: 0;
    }

    .dropdown-item:first-child {
        border-top: .1px solid #c7c7c7;
    }

    .dropdown-item {
        margin: 0;
        padding: 0;
    }

    .dropdown-link {
        padding: .6rem 2rem;
        padding-right: 0;
        margin: 0;
    }

    .navbar-btn {
        display: block;
        margin: 1rem auto;
        width: 30%;
        text-align: center;
    }
}

@media (max-width: 800px) {
    .navbar-container.banner-open {
        margin-top: 21rem;
    }
}