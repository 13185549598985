.banner-alert {
    display: none;
    width: 100%;
    grid-area: banneralert;
    z-index: 100;
    background-color: powderblue;
    align-items: center;
    justify-content: space-between;
}

.banner-alert.banner-on {
    display: flex;
}

/* .banner-alert.banner-off {
    display: none;
} */

.banner-content {
    margin-left: 14.6%;
    margin-right: 14.6%;
    width: 65%;
    margin-bottom: 2rem;
}

.banner-close-button {
    position: absolute;
    top: 1rem;
    right: 17%;
    text-align: right;
    font-size: 32px;
    cursor: pointer;
}