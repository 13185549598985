.wiki-info {
    padding: 3rem 15%;
}

.wiki-info-divider {
    border: none;
    border-top: 4px solid #37BAFF;
    margin: 2rem 0;
    width: 35%;
}

.bullet-section {
    background-color: #ECF8FF;
    padding: 4rem 14%;

}

.bullet-heading {
    text-align: center;
    padding: 0 10%;
}

.bullet-list {
    margin-top: 2rem;
    list-style: none;
    padding-right: 4%;
    /* list-style-image: url(../../images/bulletpoint-check.svg); */
    /* list-style-position: inside; */
}

.bullet {
    background: url(../../images/bulletpoint-check.svg) no-repeat 0 3px;
    padding-left: 2.5rem;
}

.bullet:not(:last-child) {
    margin-bottom: 1.5rem;
}

.bullet::before {
    /* margin-right: 1rem; */
}

.help-you {
    padding: 5rem 15%;
    text-align: center;
}

.help-you-boxes {
    margin-top: 3rem;
    padding: 0 10%;
}

.help-you-top-boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}

.help-you-top-box {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin-bottom: 2rem;
}

.help-you-top-box, .help-you-bottom-box {
    display: block;
    text-decoration: none;
    border-radius: 30px;
    padding: 3rem 10%;
    margin-left: 1rem;
    margin-right: 1rem;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
}

.help-you-top-box:hover, .help-you-bottom-box:hover {
    opacity: .9;
}

.top-box-personal {
    background-color: #0F57AC;
}

.top-box-companies {
    background-color: #37BAFF;
}

.help-you-bottom-box {
    /* width: calc(90% - 6rem); */
    background-color: #4382BD;
}

