.Typewriter {
    display: inline-block;
}

.heading-typing {
    /* display: block; */
    /* min-height: 20%; */
    /* max-width: 50%; */
}

.hero-carousel-container {
	position: absolute;
	top: 52%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	    -ms-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	width: 84%;
}

.hero-button {
	margin-right: 1rem;
}

.hero-box {
	padding: 2rem 27% 2rem 8%;
	width: 65%;
	z-index: 10;
}

.heading-primary {
	display: block;
	line-height: 55px;
	font-weight: 900;
	font-size: 3.4rem;
}

.heading-subtext {
	font-style: normal;
	font-weight: normal;
	font-size: 1.5rem;
	line-height: 33px;
	color: #363636;
	width: 85%;
	margin-bottom: 3rem;
}

@media (max-width: 960px) {

	.hero {
		/* background: linear-gradient(
			to top right,
			rgba(160, 219, 255, 0.2) 0%,
			rgba(160, 219, 255, 0.2) 65%,
			transparent 65%,
			transparent 100%
		),
		linear-gradient(
			to top left,
			#60c3ff 0%,
			#60c3ff 50%,
			transparent 50%,
			transparent 100%
		),
		#37baff;
		background-repeat: no-repeat;
		background-size: cover; */
	}

    .hero-carousel-container {
		width: 100%;
    }

	.heading-primary {
		font-size: 2.8rem;
	}

	.heading-subtext {
		font-size: 1.2rem;
		width: 100%;
	}

    .hero-button {
        margin-right: 1rem;
    }
    
    .hero-box {
		padding: 2rem 15% 2rem 15%;
        /* width: 100%; */
		/* padding-left: 15%; */
		/* padding-right: 15%; */
		/* margin-left: 2rem; */
    }

	.hero-button:not(:last-child) {
		margin-bottom: 1rem;
	}
}