.testimonial-card-container {
    /* min-height: 14rem; */
    /* width: 35rem; */
    /* max-width: 35%; */
    /* width: 10rem; */
    position: relative;
    min-width: 100%;
    margin: 0;
    /* background-color: #ffffff; */
    -webkit-transition: all 1s;
    -o-transition: all 1s;
    transition: all 1s;
    padding: 1rem 0;
}

.testimonial-card-icon {
    width: 50px;
}

.testimonial-card-text {
    padding: 0 4rem;
    font-size: 1rem;
    font-weight: 400;
    color: #262626;
}

.testimonial-card-author {
    margin-top: .5rem;
    display: block;
}