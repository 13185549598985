.cta-section {
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.cta-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 4rem 6%;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.cta-box-1 {
    background: #9CDEFF;

}

.cta-box-2 {
    background: #6DC8FF;

}

.cta-box-3 {
    background: #ADF5FF;

}

.cta-box-button {
    /* width: 35%; */
    max-height: 3rem;
    margin-top: 2rem;
}

.light-arrow-icon {
    position: absolute;
    right: 1.6rem;
    top: 55%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    display: inline;
}

#have-questions-text {
    color: #005495;
}