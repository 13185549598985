.privacy-laws {
	padding: 2.5rem 15%;
}

.go-back-btn {
	padding: 0.5rem 4rem;
}

.privacy-laws-dropdown-container {
    position: relative;
    display: block;
    margin: 1rem 0;
    width: max(15rem, 50%);
}
.privacy-laws-dropdown-container:hover {
    cursor: pointer;
}

.privacy-laws-dropdown {
	padding: 0.5rem 0.8rem;
    padding-right: 15%;
    width: 100%;
	font-size: 1rem;
	font-family: "Avenir";
	border-radius: 0;
	border: 1px solid #c7c7c7;
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	position: relative;
	outline: none;
}

.privacy-laws-dropdown-container:after {
    content: '';
    border: 2px solid #363636;
    border-width: 0 2px 2px 0;
    padding: 5px;
    width: 0;
    position: absolute;
    top: 30%;
    right: 1rem;
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    -moz-appearance: none;
         appearance: none;
    -webkit-appearance: none;
    pointer-events: none;
}
.privacy-laws-dropdown:hover {
	cursor: pointer;
}

.privacy-laws-dropdown:focus {
	border: 1px solid #37baff;
}

.privacy-laws-submit {
	padding: 0.7rem 2.5rem;
	font-family: "Avenir";
}