.price-calculator {
    padding: 2rem 15%;
}

.price-calculator-boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 2rem;
    padding: 0 5%;
}

.price-calculator-box {
    text-align: center;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 2rem;
    border: 1px solid #c7c7c7;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    margin: 1rem;
    -webkit-box-shadow: 0 1px 5px #c7c7c7;
            box-shadow: 0 1px 5px #c7c7c7;
}

.price-calculator-money {
    font-size: 2rem;
}

.product-results {
    text-align: center;
    margin-bottom: 3rem;
}

.product-select {
    display: block;
}

.extras-input {
    display: inline-block;
    border: 1px solid #c7c7c7;
    padding: .3rem .5rem;
    border-radius: 3px;
    margin-bottom: 1rem;
    font-family: 'Avenir';
}

.checkbox {
    margin-right: .6rem;
    -webkit-transform: scale(1.3);
        -ms-transform: scale(1.3);
            transform: scale(1.3);
    margin-bottom: .6rem;
    /* border-color: #c7c7c7; */
}

.extras-label {
    margin-right: 1rem;
}

.calculate-btn {
    display: block;
    font-family: 'Avenir';
    padding: .6rem 3rem;
    margin: 1rem 0;
}

.label-heading {
    display: block;
    margin-top: 1rem;
    margin-bottom: .5rem;
}
