.need-help-container {
    text-align: left;
    color: #ffffff;
    margin-bottom: 2rem;
}

.createwiki-info-banner {
    padding: 2.5rem 14.6%;
}

.sales-tax-banner {
    background-color: #F9F9F9;
}

.add-wiki-banner {
    background-color: #004D89;
    color: #ffffff;
}

.discount-banner {
    background-color: #85D5FF;
    color: #003F70 !important;
}

.discount-heading {
    color: #003F70 !important;
}

#discount-btn {
    color:inherit;
    text-decoration: underline;
    font-weight: 600;
}

#discount-btn:hover {
    opacity: .8;
}

.come-back {
    text-align: center;
    padding: 2rem 14.6%;
}

.footer-line {
    height: 0;
    border: none;
    border-top: 1px solid #363636;
    width: 70%;
}

.smaller-btn {
	margin-top: 1rem;
	padding: 0.5rem 4rem;
    margin-right: 10px;
}

.active-btn {
    margin-top: 1rem;
	padding: 0.5rem 4rem;
    margin-right: 10px;
	color: #005495;
	background-color: #ffffff;
}

.plans-outer-container {
    text-align: center;
}