.about-container {
	padding: 4rem 15%;
}

.philosophy {
	padding: 4rem 20%;
	background-color: #e1f1ff;
}

.history {
	padding: 6rem 15%;
	padding-bottom: 7rem;
	color: #ffffff;
	background: -webkit-gradient(
			linear,
			left bottom, right top,
			from(rgba(160, 219, 255, 0.2)),
			color-stop(65%, rgba(160, 219, 255, 0.2)),
			color-stop(65%, transparent),
			to(transparent)
		),
		-webkit-gradient(
			linear,
			right bottom, left top,
			from(#60c3ff),
			color-stop(50%, #60c3ff),
			color-stop(50%, transparent),
			to(transparent)
		),
		#37baff;
	background: -o-linear-gradient(
			bottom left,
			rgba(160, 219, 255, 0.2) 0%,
			rgba(160, 219, 255, 0.2) 65%,
			transparent 65%,
			transparent 100%
		),
		-o-linear-gradient(
			bottom right,
			#60c3ff 0%,
			#60c3ff 50%,
			transparent 50%,
			transparent 100%
		),
		#37baff;
	background: linear-gradient(
			to top right,
			rgba(160, 219, 255, 0.2) 0%,
			rgba(160, 219, 255, 0.2) 65%,
			transparent 65%,
			transparent 100%
		),
		linear-gradient(
			to top left,
			#60c3ff 0%,
			#60c3ff 50%,
			transparent 50%,
			transparent 100%
		),
		#37baff;
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
}

.bottom-history-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.history-img {
	/* position: absolute;
    left: 15%;
    bottom: 5rem;
    width: 30%; */
	/* height: 10rem; */
}

.started-out {
	padding-right: 30%;
}

.set-apart {
	padding-left: 5%;
}

.different-from-competition {
	padding: 6rem 15%;
	text-align: center;
}

.different-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-ms-flex-pack: distribute;
	    justify-content: space-around;
	margin-top: 4rem;
}

.different-box {
	width: max(33%, 20rem);
	margin: 1rem 5%;
}

.different-box-img-container {
	height: 10rem;
}

.different-box-img {
	height: 9rem;
}

.different-box-title {
	font-size: 1.25rem;
	font-weight: 900;
}

.unlimited {
	padding: 4rem 15%;
	background-color: #005291;
	text-align: center;
}

.choose-plan-btn {
	padding: 0.5rem 4rem;
	display: inline-block;
	margin-top: 1rem;
}

@media (max-width: 960px) {
	.history-img {
		display: none;
	}

	.set-apart {
		padding-left: 30%;
	}
}