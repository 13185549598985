.usecases-icons-section {
	padding: 3rem 0% 0 0%;
	text-align: center;
}

.usecases-icons-heading {
	padding: 0 10%;
}

.usecases-icons-container {
	margin: 2rem;
	/* position: relative; */
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-end;
}

.icon-container {
	margin: 2rem;
}

.top-icon-row {
	width: 34%;
}

.middle-icon-row {
	width: 25%;
}

.middle-versatile {
	width: min(25%, 20rem);
}

.bottom-icon-row {
    width: 90%;
}

.usecase-1 {
	text-align: left;
	background: rgba(15, 87, 172, 1);
	padding-right: 20%;
}

.usecase-2 {
	text-align: right;
	background: rgba(26, 110, 175, 1);
	padding-left: 15%;
}

.company-wiki-icon {
	width: 100%;
}

.classrooms-icon {
	width: 100%;
}

.encyclopedias-icon {
	width: 100%;
}

.personal-use-icon {
	width: 100%;
}

.support-knowledgebase-icon {
	width: 100%;
}

.versatile-usecases-icon {
	/* position: absolute; */
	width: 100%;
}

.host-own-wiki {
	padding: 3rem 15%;
}

.how-can-we-help {
    padding: 3rem 20%;
    text-align: center;
    background: rgba(0, 77, 137, 1);
}

.how-can-we-help-btn {
    margin: 2rem;
}

.companies-and-personal-container {
    padding: 3rem 14%;
}

.companies-and-personal {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.companies-and-personal-half {
    min-width: 10rem;
    flex: 1;
    padding: 1rem 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding-bottom: 5rem;
}

.companies-and-personal-btn {
    position: absolute;
    bottom: 0;
}

.companies-and-personal-box {
    margin-top: 2rem;
}

.companies-and-personal-half:first-child {
    border-right: 1px solid rgba(54,54,54,1);
}

.information-encyclopedia-container {
    margin-top: 3rem;
    padding: 0 5%;
}

@media (max-width: 1040px) {
    .versatile-usecases-icon {
        display: none;
    }

    .middle-versatile {
        display: none;
    }

    .icon-container {
        flex: 1 !important;
        margin: 2rem 15%;
    }

    .companies-and-personal-half:first-child {
        border-right: none;
    }
    /* .top-icon-row {
        width: 34%;
    }
    
    .middle-icon-row {
        width: 25%;
    }
    
    .middle-versatile {
        width: 25%;
    }
    
    .bottom-icon-row {
        width: 90%;
    } */
}