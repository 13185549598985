.partners-container {
	background-color: #ffffff;
	text-align: center;
}

.partners-divider {
	width: 80%;
	border: none;
	border-bottom: 1px solid rgba(141, 141, 141, 1);
    padding-top: 80px;
}

.partners-text {
	font-size: 1.8rem;
	padding: 5rem 2rem 1rem 2rem;
	color: #686868;
}

.partners-icons-container {
	padding: 0 10%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.why-choose {
    padding: 4rem 10%;
    padding-top: 150px;
}

.why-choose-boxes {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.why-choose-box {
    position: relative;
    width: 27%;
    margin: 1.5rem;
}

.why-choose-box-number {
    color: #c7c7c7;
    font-family: 'Baloo', 'Avenir';
    font-size: 3.12rem;
    font-weight: 900;
    margin-bottom: 1rem;
}

.why-choose-box-text {
    margin-top: 1rem;
}

.why-choose-icon {
    position: absolute;
    bottom: -1rem;
    left: calc(-16vw);
    max-width: 480px;
    /* left: 0; */
    /* transform: translateY(10%); */
    /* width: 35%; */

}

.example-services {
	padding: 4rem 15%;
	background-color: #005291;
	color: #ffffff;
	text-align: center;
}

.examples-container {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-ms-flex-pack: distribute;
	    justify-content: space-around;
	margin-bottom: 2rem;
}

.example-service {
	width: 34%;
	margin: 2rem 1rem;
}

.example-service-img-container {
	height: 10rem;
    margin-bottom: 1rem;
}

.example-service-img {
    height: 100%;
    max-width: 10rem;
}

@media (max-width: 1060px) {
    .example-service {
        width: 100%;
    }

    .why-choose-box {
        width: 70%;
    }

    .empty-box {
        width: 0;
        display: none;
    }

    .why-choose-icon {
        display: none;
    }
}