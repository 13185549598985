.compare-hosts-container {
    padding: 3rem 15%;
    background-color: #37baff;
}

.compare-hosts-table, tr, th, td {
    border: 1px solid #c7c7c7;
    padding: 1rem;
    border-collapse: collapse;
    background-color: #ffffff;
    text-align: left;
    text-align: center;

}

.compare-hosts-table {
    border-radius: 15px !important;
    overflow: hidden;
}