.footer {
    font-family: 'Proxima Nova', 'Roboto', sans-serif;
    font-size: 1rem;
    text-align: center;
    letter-spacing: 0.2px;
    padding: 2rem 1rem 1rem 1rem;
}

.footer-name {
    font-size: 1.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
}

.footer-links-container {
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.footer-link {
    padding: 0 10px;
    text-decoration: none;
}

.footer-link:hover {
    text-decoration: underline;
    color: #337ab7;
}

.copyright-text {
    font-weight: 100;
    color: #686868
}