.card-details-container {
    display: flex;
    color: black;
}

.extension-card {
    border-radius: 20px;
    padding:1rem;
    margin: 2rem;
    display: flex;
    border: 1px solid #E2E2E2;
    position: relative;
}

.extension-info h2 {
    font-weight: 500;
}

.extension-info p {
    font-size: 20px;
    font-weight: 300;
 
}