.features-container {
	text-align: center;
	width: 100%;
}

.features-statement-banner {
	color: #ffffff;
	padding: 3rem 15%;
}

.features-statement-subtext {
	font-size: 1.5rem;
}

.features-privacy-container {
	padding: 3rem 15%;
	text-align: center;
}

.features-privacy-heading {
	font-size: 1.6rem;
}

.features-privacy-body {
	/* margin: auto; */
	/* width: 80%;  */
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-ms-flex-pack: distribute;
	    justify-content: space-around;
}

.features-privacy-box {
	width: 20rem;
	margin: 1rem;
}

.features-privacy-box-img-container {
	height: 7rem;
}

.privacy-img {
	/* width: 200px; */
}

.features-privacy-box-title {
	font-size: 1.25rem;
	font-weight: 900;
}

.features-editor-container {
	padding: 7rem 15%;
	color: #ffffff;
	background: -webkit-gradient(
			linear,
			left bottom, right top,
			from(rgba(160, 219, 255, 0.2)),
			color-stop(65%, rgba(160, 219, 255, 0.2)),
			color-stop(65%, transparent),
			to(transparent)
		),
		-webkit-gradient(
			linear,
			right bottom, left top,
			from(#60c3ff),
			color-stop(50%, #60c3ff),
			color-stop(50%, transparent),
			to(transparent)
		),
		#37baff;
	background: -o-linear-gradient(
			bottom left,
			rgba(160, 219, 255, 0.2) 0%,
			rgba(160, 219, 255, 0.2) 65%,
			transparent 65%,
			transparent 100%
		),
		-o-linear-gradient(
			bottom right,
			#60c3ff 0%,
			#60c3ff 50%,
			transparent 50%,
			transparent 100%
		),
		#37baff;
	background: linear-gradient(
			to top right,
			rgba(160, 219, 255, 0.2) 0%,
			rgba(160, 219, 255, 0.2) 65%,
			transparent 65%,
			transparent 100%
		),
		linear-gradient(
			to top left,
			#60c3ff 0%,
			#60c3ff 50%,
			transparent 50%,
			transparent 100%
		),
		#37baff;
	background-repeat: no-repeat;
	background-size: cover;
}

.features-editor {
	margin: auto;
	text-align: left;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
	-ms-flex-pack: distribute;
	    justify-content: space-around;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
}

.features-editor-text {
	width: 50%;
}

.features-editor-heading {
	font-size: 1.6rem;
}

.smaller-btn {
	margin-top: 1rem;
	padding: 0.5rem 4rem;
}

.try-it-out-btn:hover {
	color: #005495;
}

.features {
	color: #ffffff;
}

.feature-box-left {
	/* max-height: 20rem; */
	margin: 2rem 7.5% 2rem 0;
	padding: 4rem 7.5% 4rem 14%;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	background: #6aafc9;
	text-align: left;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.feature-box-right {
	/* max-height: 20rem; */
	margin: 2rem 0 2rem 7.5%;
	padding: 4rem 15% 4rem 7.5%;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	background: #5998d2;
	text-align: left;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	    -ms-flex-pack: justify;
	        justify-content: space-between;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.feature-box-text {
	/* height: 10rem; */
	width: 50%;
	/* margin: 1rem; */
}

.feature-1 {
	background: #0f57ac;
}

.feature-2 {
	background: #1a6eaf;
}

.feature-3 {
	background: #0a3282;
}

.feature-4 {
	background: #088ec8;
}

.feature-5 {
	background: #4e89bf;
}

.feature-6 {
	background: #1a6eaf;
}

.features-support {
    position: relative;
    padding: 2rem 15% 5rem 15%;
    background: #005291;
    color: #ffffff;
    text-align: left;
}

.support-text {
    margin-right: 50%;
    margin-bottom: 2rem;
}

.typing-bubble-icon {
    margin-right: 10px;
    -webkit-transform: translateY(1px);
        -ms-transform: translateY(1px);
            transform: translateY(1px);
}

.tech-support-icon {
    position: absolute;
    right: 25%;
    bottom: 3rem;
}


@media (max-width: 960px) {
	.features-editor-text {
		width: 100%;
	}

	.feature-box-text {
		width: 100%;
	}

	.feature-box-left .feature-box-img {
		margin-top: 3rem;
		-webkit-box-flex: 1;
		    -ms-flex: 1;
		        flex: 1;
	}

	.feature-box-right .feature-box-img {
		margin-bottom: 2rem;
	}

	.feature-box-img {
		width: 80%;
	}

	.support-text {
		margin-right: 0;
		margin-bottom: 2rem;
	}

	.tech-support-icon {
		display: none;
	}
}