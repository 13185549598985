.what-level {
	padding: 3rem 15%;
}

.what-level-calculator-container {
	margin-top: 2rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	    flex-wrap: wrap;
}

.what-level-calculator {
	margin: 1rem;
}

.level-box {
	-webkit-box-flex: 2;
	    -ms-flex: 2;
	        flex: 2;
}

.privacy-criteria {
	font-size: 1.5rem;
	font-weight: 900;
	margin-bottom: 0.8rem;
}

.privacy-criteria:not(:first-child) {
	margin-top: 0.8rem;
}

.radio-container {
    width: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    overflow: hidden;
    border-radius: 15px;
    /* box-shadow: 0 0 2px rgba(0,0,0,.25); */
    border: 1px solid rgba(0, 84, 149, 0.7);
}

.criteria-radio {
    display: none;
    
}

.criteria-label {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: .5rem .8rem;
    color: rgba(0, 84, 149, 0.7);
    cursor: pointer;
    -webkit-transition: all .2s;
    -o-transition: all .2s;
    transition: all .2s;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.criteria-radio:checked + .criteria-label {
    background-color: rgba(0, 84, 149, 0.7);
    color: #ffffff;
}

.criteria-label:not(:last-of-type) {
    border-right: 1px solid rgba(0, 84, 149, 0.7);

}

.createacc-admin {
    font-size: .9rem;
}

.desired-level-container {
	-webkit-box-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	border-radius: 15px;
	-webkit-box-pack: center;
	    -ms-flex-pack: center;
	        justify-content: center;
	-webkit-box-align: center;
	    -ms-flex-align: center;
	        align-items: center;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	    -ms-flex-direction: column;
	        flex-direction: column;
	/* margin: auto; */
	background-color: rgba(0, 84, 149, 0.7);
	padding: 3rem 5%;
	font-size: 1.5rem;
	margin: 1rem;
	text-align: center;
}

.desired-level {
	font-size: 2rem;
	line-height: 1.2;
}

.privacy-table-container {
	background-color: #37baff;
    padding: 3rem 15%;
}

.privacy-table {
    margin-top: 2rem;
    background-color: #ffffff;
}

.criteria-column-label {
    font-size: 1.5rem;
    font-weight: 800;
    margin: 1rem 0;
    /* padding-left: 13%; */
    /* padding: 0 15%; */
}

.top-left-cell {
    padding-left: 13.4%;
}

.everyone {
    background-color: #cef2e0;

}

.usersonly {
    background-color: #E88E89;
}

.adminapprove {
    background-color: rgba(65, 125, 255, 0.527);
}

.plans-table-cell {
    display: flex;
    justify-content: center;
}

@media (max-width: 1040px) {
    .privacy-header-row {
        display: none;
    }
}