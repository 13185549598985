.addons-section {
    padding: 4rem 15%;
    text-align: center;
}

.addons-container {
    width: 100%;
    margin-top: 2rem;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.addon-box {
    -ms-flex-preferred-size: 34%;
        flex-basis: 34%;
    position: relative;
    padding: 2rem;
    margin: 1rem;
    border-radius: 20px;
    border: 1px solid #E2E2E2;
    overflow: hidden;
}

.addon-box-full {
    -ms-flex-preferred-size: 74%;
        flex-basis: 74%;
    position: relative;
    padding: 2rem;
    margin: 1rem;
    border-radius: 20px;
    border: 1px solid #E2E2E2;
    overflow: hidden;
}

.addon-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.addon-short-description {
    font-weight: 400;
    color: #8d8d8d;
    font-size: 1.2rem;
}

.addon-cost-frequency, .addon-tax-description, .addon-pre-price-description {
    font-weight: 400;
    color: #8d8d8d;
    font-size: .7rem;
}

.addon-cost {
    font-size: 3.75rem;
    font-weight: 800;
    color: #0031dd;
}

@media (max-width: 960px) {
    .addon-box {
        -ms-flex-preferred-size: 100%;
            flex-basis: 100%;
    }
}