.plans-table-container {
    margin-top: 1rem;
    padding: 1rem 2rem;
	background-color: #ffffff;
	text-align: left;
	border-radius: 33px;
	position: relative;
}

.column-pro {
    background-color: rgba(148, 218, 255, .2);
}

.feature-name {
    font-size: 1.2rem;
    font-weight: 800;
    padding: .5rem 1.5rem;
    border-bottom: .5px solid #363636;
}

#feature-name-inline {
    display: none;
}

.plans-table-header-cell {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

#empty-header-cell {
    padding: .5rem 1.5rem;
}

.plans-table-row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    border-bottom: .5px solid #c7c7c7;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.plans-table-cell {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 1rem 2rem;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}

.plan-title {
    border-bottom: .5px solid #c7c7c7;
    position: relative;
    text-transform: capitalize;
    color: #363636;
    font-size: 1.5rem;
    font-weight: 800;
    padding: .5rem 2rem;
    padding-top: 1.5rem;
}

.plan-cost {
    /* display: block; */
    position: relative;
    font-size: 2.5rem;
    font-weight: 800;
    color: #0031DD;
}

.plan-cost-cents {
    font-size: 1.56rem;
    vertical-align: super;
}
.plan-cost-description {
    font-size: .7rem;
    font-weight: 400;
    color: #8d8d8d;
}

.plan-cost-starting-at {
    margin: 0.5px;
}

.plan-description-container {
    padding: .5rem 2rem;
    font-size: rem;
    font-weight: 800;
    color: #363636
}

.green-checkmark {
    width: 1.3rem;
}

.recommended-banner {
    position: absolute;
    right: 0;
    top: 20%;
    font-size: .8rem;
    font-weight: 800;
    padding: .5rem 1.5rem;
    padding-right: 1rem;
    color: #ffffff;
    background-color: #49C4FF;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.plans-table-cell-title {
    /* font-weight: 900; */
    color: #808080;
    text-transform: uppercase;
    margin-bottom: 1rem;
}

.plans-table-cell-content {
    margin-top: 1rem;
}

.plans-table-btn-row {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.select-plan-btn {
    text-align: center;
    width: 60%;
    padding: .8rem 2rem;
    margin: auto;
    margin-bottom: 1rem;
}

.cell-hide {
    display: none;
}

#plans-btn-hide {
    display: none;
}

.cell-text {
    text-align: left;
}

@media (min-width: 1040px) {

    .plans-table-header-cell {
        width: 20%;
    }

    .cell-hide {
        display: initial;
    }

    #plans-btn-hide {
        display: initial;
    }

    .cell-text {
        text-align: center;
    }

    .feature-name {
        display: none;
    }

    #feature-name-inline {
        display: initial;
        display: flex;
        justify-content: center;
    }

    .plans-table-row {
        margin: 0;
    }

    .plans-table-cell {
        position: relative;
        padding: 1rem 2rem;
    }

    .plans-table-cell-title {
        display: none;
    }

    .green-checkmark {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
    }

    .plans-table-btn-row .plans-table-cell {
        padding-top: 3rem;
    }

    .select-plan-btn {
        font-size: .96rem;
    }
}