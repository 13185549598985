.services-container {
	text-align: center;
	color: #ffffff;
	background-color: #005291;
	padding:4rem 5rem;
}

.learn-more-btn {
	padding: .5rem 4rem;
}

.services-subtext {
	font-size: 1.3rem;
}

.services-icon {
	display: block;
	margin: 2.7rem auto;
	width: min(290px, 80%);
}

.learn-more-btn:hover {
	color: #005291 !important;
}